<template>
  <div>

    <v-row>
      <v-col>

        <v-card tile class="card_section">
          <v-card-text>

            <v-row>
              <v-col cols="12" md="6" lg="4">
                <v-menu
                  v-model="dateFromDialog"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFrom"
                      label="Od"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="dateFrom = null"
                      @input="getCosts()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFrom"
                    @input="dateFromDialog = false; getCosts()"
                    :max="today"
                    locale="pl-pl"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-menu
                  v-model="dateToDialog"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateTo"
                      label="Do"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      clearable
                      @click:clear="dateTo = null"
                      @input="getCosts()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateTo"
                    @input="dateToDialog = false; getCosts()"
                    :max="today"
                    locale="pl-pl"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-card-text style="text-align: right">
                  <v-btn
                    color="secondary_dark"
                    dark
                    depressed
                    tile
                    :to="'/dodaj-koszt/'+project_id"
                  >
                    Dodaj koszt
                  </v-btn>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="6" v-if="select_project">
                <v-autocomplete
                  v-model="selected_project"
                  :items="projects"
                  item-text="name"
                  item-value="id"
                  no-data-text="Brak wyników"
                  label="Wybierz projekt"
                  @change="projectCosts()"
                  clearable
                ></v-autocomplete>
              </v-col>

            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Wyszukaj"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            
            <v-data-table
              class="classic_table"
              :search="search"
              :headers="headers"
              :items="costs"
              item-key="name"
              @click:row="costClick"
              :footer-props="{
                itemsPerPageText : 'Elementów na stronie',
                itemsPerPageAllText : 'Wszystkie',
                itemsPerPageOptions : [15,45,90,-1],
              }"
              :loading="loading_costs"
              loading-text="Pobieranie danych..."
            >
              <template v-slot:no-data>Brak danych</template>
              <template v-slot:no-results>Brak wyników</template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
              </template>

              <template
                v-slot:item.price_netto="{ item }"
              >
                {{item.price_netto}} zł
              </template>
              <template
                v-slot:item.price_brutto="{ item }"
              >
                {{item.price_brutto}} zł
              </template>
            </v-data-table>

            <!-- <Charto /> -->
            <!-- TEST -->

          </v-card-text>
          
        </v-card>

      </v-col>
    </v-row>
    
    
  </div>
</template>

<script>
// #TODO albo usunac albo zmienic
// import Charto from '@/components/Projects/chart_pie.vue';


export default {
  components: {
    // Charto
  },
  props: {
    project_id: String,
    select_project: Boolean
  },
  data: () => ({

    headers: [
      { text: 'Nazwa', value: 'name' },
      { text: 'Opis', value: 'description' },
      { text: 'Cena netto', value: 'price_netto' },
      { text: 'Cena brutto', value: 'price_brutto' },
      { text: 'Data', value: 'date_created' }
    ],

    data: {
      progress: 0,
      sprints: [],
      user_name: '',
      done: 0
    },

    dates: [],
    date: '',
    today: new Date().toISOString().substr(0, 10),

    dateFromDialog: false,
    dateFrom: null,
    dateToDialog: false,
    dateTo: null,

    costs: [],
    loading_costs: true,

    search: '',

    projects: [],
    selected_project: null
  }),
  methods: {
    // Przejscie tylko mozliwe jezeli wyswietlamy wszystkie
    costClick(value) {
      if(this.select_project) this.$router.push('/projekt/'+value.project_id);
    },
    getCosts(){
      
      this.loading_costs = true;

      let formData = new FormData();

      console.log(this.project_id);
      if(this.project_id) formData.append("project_id", this.project_id);

      // Dodawanie project id jezeli recznie wybieramy projekt z selecta
      if(this.selected_project) formData.append("project_id", this.selected_project);

      // Jeżeli została wybrana data OD
      if(this.dateFrom) {
        // Odwracanie daty
        let newDateFrom = this.global_flipDate(this.dateFrom);
        formData.append("date_start", newDateFrom);
      }

      // Jeżeli została wybrana data DO
      if(this.dateTo) {
        let newDateTo = this.global_flipDate(this.dateTo);
        formData.append("date_end", newDateTo);
      }

      this.$axios({url: this.$store.state.api +'/project_date_cost', data: formData, method: 'POST' })
        .then(resp => {
          this.costs = resp.data
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          // this.$store.commit('loader_off');
          this.loading_costs = false;
        })
    },
    // Metoda bezpieczeństwa która koryguje daty
    // #TODO zrobic blokade, nie mozna wybrac daty mniejszej od zakresu OD
    securityCheck(){
        // console.log(this.dateFrom)
        // console.log(this.dateTo)
        // if(this.dateFrom > this.user_data.date_invoice) this.user_data.date_invoice = this.user_data.date_create;
    },
    getProjectCosts(){
      if(this.select_project){
        this.$axios({url: this.$store.state.api +'/project' , data: {}, method: 'GET' })
          .then(resp => {
            this.projects = resp.data;
            console.log(resp.data);
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
    projectCosts(){
      this.getCosts();
    }
  },
  mounted(){
    this.getCosts();
    if(this.select_project) this.getProjectCosts();
  }
};
</script>

<style lang="scss" scoped>
</style>