var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"card_section",attrs:{"tile":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Od","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.dateFrom = null},"input":function($event){return _vm.getCosts()}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateFromDialog),callback:function ($$v) {_vm.dateFromDialog=$$v},expression:"dateFromDialog"}},[_c('v-date-picker',{attrs:{"max":_vm.today,"locale":"pl-pl","color":"primary"},on:{"input":function($event){_vm.dateFromDialog = false; _vm.getCosts()}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Do","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){_vm.dateTo = null},"input":function($event){return _vm.getCosts()}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateToDialog),callback:function ($$v) {_vm.dateToDialog=$$v},expression:"dateToDialog"}},[_c('v-date-picker',{attrs:{"max":_vm.today,"locale":"pl-pl","color":"primary"},on:{"input":function($event){_vm.dateToDialog = false; _vm.getCosts()}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card-text',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"color":"secondary_dark","dark":"","depressed":"","tile":"","to":'/dodaj-koszt/'+_vm.project_id}},[_vm._v(" Dodaj koszt ")])],1)],1),(_vm.select_project)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.projects,"item-text":"name","item-value":"id","no-data-text":"Brak wyników","label":"Wybierz projekt","clearable":""},on:{"change":function($event){return _vm.projectCosts()}},model:{value:(_vm.selected_project),callback:function ($$v) {_vm.selected_project=$$v},expression:"selected_project"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"classic_table",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.costs,"item-key":"name","footer-props":{
              itemsPerPageText : 'Elementów na stronie',
              itemsPerPageAllText : 'Wszystkie',
              itemsPerPageOptions : [15,45,90,-1],
            },"loading":_vm.loading_costs,"loading-text":"Pobieranie danych..."},on:{"click:row":_vm.costClick},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v("Brak danych")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v("Brak wyników")]},proxy:true},{key:"footer.page-text",fn:function(ref){
            var pageStart = ref.pageStart;
            var pageStop = ref.pageStop;
            var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" z "+_vm._s(itemsLength)+" ")]}},{key:"item.price_netto",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.price_netto)+" zł ")]}},{key:"item.price_brutto",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.price_brutto)+" zł ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }