<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  :color="data.done?'error':'primary'"
                  text
                  :to="'/projekt-edytuj/'+id"
                  v-if="$store.state.user_permissions.project_update"
                >
                  Status: {{data.done ?'Zakończony':'Otwarty'}}
                </v-btn>
                <v-btn
                  color="secondary_dark"
                  dark
                  depressed
                  tile
                  :to="'/projekt-edytuj/'+id"
                  v-if="$store.state.user_permissions.project_update"
                >
                  Edytuj projekt
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        <v-row align="center">
          <v-col cols="12" md="8">
            <v-card tile>
              <v-card-title>{{data.name}}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <h3>Klient:</h3>
                    <p>{{data.assign_user_name}}</p>
                  </v-col>
                  <v-col cols="12" md="6">
                    <h3>Opiekun projektu:</h3>
                    <p>{{data.guardian_name}}</p>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-expansion-panels flat>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="description_collapse"><b>Opis</b> <span>(Rozwiń)</span></span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    {{data.description}}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </v-card>
          </v-col>
          <v-col cols="12" md="4" style="display:flex;justify-content: center;flex-wrap: wrap">
            <h2 style="width: 100%; text-align: center">Projekt ukończony w</h2>
            <v-progress-circular
              :value="data.progress" 
              :rotate="-90"
              :size="120"
              color="primary"
              style="font-size: 2rem;font-weight: 700"
            >{{data.progress}} %</v-progress-circular>
          </v-col>
        </v-row>

        <v-card flat class="card_action">
          <v-card-actions>
            <v-spacer/>
            <v-btn
              color="secondary_dark"
              dark
              depressed
              tile
              :to="'/projekt-sprint-nowy/'+id"
              v-if="$store.state.user_permissions.project_sprint_create"
            >
              Dodaj etap
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card 
          tile 
          v-if="$store.state.user_permissions.project_sprints"
        >
          <v-card-title>Etapy</v-card-title>
          <v-card-text>
            
            <v-data-table
              class="classic_table"
              :headers="headers"
              :items="data.sprints"
              item-key="name"
              @click:row="invoiceClick"
              :footer-props="{
                itemsPerPageText : 'Elementów na stronie',
                itemsPerPageAllText : 'Wszystkie',
                itemsPerPageOptions : [15,45,90,-1],
              }"
            >
              <template v-slot:no-data>Brak danych</template>
              <template v-slot:no-results>Brak wyników</template>
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>

        <v-card 
          tile 
          class="card_section"
          v-if="$store.state.user_permissions.project_cost_create"
        >
          <v-card-title>Koszty projektu</v-card-title>
          <v-card-text>
            <!-- <Chart
              :costs="40"
              :earnings="60"
            /> -->

            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">
                       Netto
                    </th>
                    <th class="text-left">
                       Brutto
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Budżet projektu</td>
                    <td>{{data.budget_netto}} zł</td>
                    <td>{{data.budget_brutto}} zł</td>
                  </tr>
                  <tr>
                    <td>Koszty netto</td>
                    <td>{{data.cost_netto}} zł</td>
                    <td>{{data.cost_brutto}} zł</td>
                  </tr>
                  <tr>
                    <td>Saldo końcowe</td>
                    <td>{{data.expenses_netto}} zł</td>
                    <td>{{data.expenses_brutto}} zł</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card-text>
        </v-card>

        <Costs
          :project_id="id"
          v-if="$store.state.user_permissions.project_cost_create"
        />

      </v-col>
    </v-row>
    
    
  </div>
</template>

<script>
// #TODO dodac log kosztow (przeniesc z kosztow i zrobic przekierowanie na projekty, koszty posiadaja tylko 1 tabelke) i moze wykres kolowy zielony budzet - czerwony koszty
import Costs from '@/components/Projects/Select/Costs.vue';

export default {
  components: {
    Costs
  },
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Projekt',
        disabled: true,
        to: '/proje',
      },
    ],

    headers: [
      { text: 'Nazwa', value: 'name' },
      { text: 'Status', value: 'status' },
      { text: 'Przewidywany czas (godziny)', value: 'hours' }
    ],

    id: 0,

    data: {
      progress: 0,
      sprints: [],
      user_name: '',
      done: 0
    },
  }),
  methods: {
    invoiceClick(value) {
      if(this.$store.state.user_permissions.project_sprint_show)
        this.$router.push('/sprint/'+value.id);
    },
  },
  beforeMount(){
    this.id = this.$route.params.id;
  },
  mounted(){
    console.log(this.id);

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/project/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        this.data = resp.data;
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })

  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}

.description_collapse {
  b {
    font-size: 1.2rem
  }
  span {
    font-size: .9rem;
    color: rgb(70, 70, 70);
  }
}
</style>